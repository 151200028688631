const Preload = 'preload'
const TitleScreen = 'titlescreen'
const Game = 'game'
const GameBackground = 'game-background'
const GameOver = 'game-over'

export {
    Preload,
    TitleScreen,
    Game,
    GameBackground,
    GameOver
}
